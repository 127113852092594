import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import qs from 'qs';

import { decodeItem, encodeItem } from '../../../helpers';
import FilterCheckbox from './FilterCheckbox/FilterCheckbox';
import gtm from '../../../utils/initialGTM';
import { filterValidStreamsAsObject } from '../../../utils/filterValidStreams';

import './SidebarFilter.scss';

export class SidebarFilter extends Component {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    selected: PropTypes.object,
    onChange: PropTypes.func,
    children: PropTypes.node,
    location: PropTypes.object,
    history: PropTypes.object,
    extClass: PropTypes.string,
    searchFilter: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedFilters: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { selected, filters = [], searchFilter } = props;

    const queryParams = filterValidStreamsAsObject(props.location.search);

    Object.keys(queryParams).forEach((groupName) => {
      if (typeof queryParams[groupName] === 'string') {
        queryParams[groupName] = [queryParams[groupName]];
      }
    });

    let allFilters = filters;
    if (selected) {
      allFilters = filters.concat([selected]);
    }

    const selectedFilters = {
      searchInput: searchFilter,
    };
    allFilters.forEach((group) => {
      selectedFilters[group.name] =
        state.selectedFilters[group.name] || queryParams[group.name] || [];
    });

    return {
      selectedFilters,
    };
  }

  componentDidMount() {
    document.addEventListener('ClearAgendaFilters', this.resetFilters);
  }

  componentWillUnmount() {
    document.removeEventListener('ClearAgendaFilters', this.resetFilters);
  }

  resetFilters = () => {
    const { filters = [] } = this.props;
    const defaultFilters = {};

    filters.forEach((filter) => {
      defaultFilters[filter.name] = [];
    });

    this.setState({
      selectedFilters: defaultFilters,
    });
  };

  onChange(event, groupName) {
    const itemValue = decodeItem(event.target.value);
    const itemChecked = event.target.checked;
    const { selectedFilters } = this.state;
    const itemIndex = selectedFilters[groupName].indexOf(itemValue);

    if (itemIndex === -1) {
      selectedFilters[groupName].push(itemValue);
    } else {
      selectedFilters[groupName].splice(itemIndex, 1);
    }

    this.setSelectedFilters(event);
    gtm.pushFilter(groupName, itemValue, itemChecked);

    return itemChecked;
  }

  setSelectedFilters(event) {
    const { selectedFilters } = this.state;
    const searchString = qs
      .stringify(selectedFilters, { encode: true, indices: false })
      .replace(/%20/g, '+');

    this.props.history.replace(
      this.props.location.pathname + searchString ? '?' + searchString : '',
    );

    this.setState({
      selectedFilters,
    });

    this.props.onChange && this.props.onChange(event);
  }

  static toggleFilterGroupList(event) {
    const groupContainerNode = event.target.closest(
      '.c-sidebar-filter__content-group',
    );

    if (groupContainerNode.classList.contains('closed')) {
      groupContainerNode.classList.remove('closed');
    } else {
      groupContainerNode.classList.add('closed');
    }
  }

  clearFilterBlock(groupName) {
    const { selectedFilters } = this.state;

    if (selectedFilters[groupName].length) {
      selectedFilters[groupName].length = 0;
      this.setSelectedFilters(this, true);
    }
  }

  render() {
    const { filters, selected, children, extClass, t } = this.props;
    const { selectedFilters } = this.state;

    return (
      <div className="c-sidebar-filter">
        <div className="c-sidebar-filter__content">
          {children}
          {selected && selected.items && selected.items.length > 0 && (
            <div className="c-sidebar-filter__content-group">
              <div className="c-sidebar-filter__content-group-title">
                <div
                  className="col-sm-8"
                  onClick={SidebarFilter.toggleFilterGroupList}
                >
                  {selected.title}
                </div>
                <div className="col-sm-4 text-right btn-clear">
                  {t('event.clear')}
                </div>
              </div>
              <div className="c-sidebar-filter__content-group-list">
                <ul>
                  {selected.items
                    .filter((item) => item.name)
                    .map((item, index) => (
                      <FilterCheckbox
                        key={index}
                        value={encodeItem(item.name)}
                        label={item.name}
                        checked={
                          selectedFilters[selected.name].indexOf(
                            decodeItem(item.name),
                          ) > -1
                        }
                        onChange={(event) =>
                          this.onChange(event, selected.name)
                        }
                      />
                    ))}
                </ul>
              </div>
            </div>
          )}
          {filters &&
            filters.map((group, index) => (
              <div
                key={index}
                className={`c-sidebar-filter__content-group ${extClass || ''}`}
              >
                {group.items.length > 0 && (
                  <div className="c-sidebar-filter__content-group-title row">
                    <div
                      className="col-sm-8 col-xs-6"
                      onClick={SidebarFilter.toggleFilterGroupList}
                    >
                      {group.title}
                    </div>
                    {selectedFilters[group.name].length > 0 && (
                      <div
                        className="col-sm-4 col-xs-6 text-right btn-clear"
                        onClick={() => this.clearFilterBlock(group.name)}
                      >
                        {t('event.clear')}
                      </div>
                    )}
                  </div>
                )}
                <div className="c-sidebar-filter__content-group-list">
                  <ul>
                    {group.items
                      .filter((item) => item.name)
                      .map((item, index) => {
                        const isFilterId =
                          group.name === 'stream' || group.name === 'day';
                        const itemName =
                          group.name === 'day'
                            ? item.name
                            : item.name?.toUpperCase();

                        return (
                          itemName !== 'NONE' && (
                            <FilterCheckbox
                              key={index}
                              value={
                                isFilterId
                                  ? item.filterId
                                  : encodeItem(item.value || item.name)
                              }
                              checkboxName={group.name}
                              logo={item.logo}
                              label={
                                group.name === 'format'
                                  ? t(`AgendaSessionFormat.${itemName}`)
                                  : item.name
                              }
                              checked={
                                selectedFilters[group.name].indexOf(
                                  isFilterId
                                    ? item.filterId
                                    : decodeItem(item.value || item.name),
                                ) > -1
                              }
                              onChange={(event) =>
                                this.onChange(event, group.name)
                              }
                              colorIndex={item.color}
                            />
                          )
                        );
                      })}
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(SidebarFilter));
