import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { getImgixUrl } from '../../../helpers';

import './AgendaStreamLogo.scss';

export function AgendaStreamLogo(props) {
  const {
    logo,
    logoName = '',
    pageConfig: { tenantId } = {},
    isFilter = false,
  } = props;

  const logoClass = classnames('c-agenda-stream-logo', {
    'c-agenda-stream-logo--filter': isFilter,
  });

  return (
    <img
      src={getImgixUrl(tenantId, logo, 'w=80&h=60')}
      alt={logoName}
      className={logoClass}
    />
  );
}

AgendaStreamLogo.propTypes = {
  logo: PropTypes.string,
  logoName: PropTypes.string,
  pageConfig: PropTypes.object,
  isFilter: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(AgendaStreamLogo);
