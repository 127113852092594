import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './MobileSidebar.scss';

export default class MobileSidebar extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary']),
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onClose: PropTypes.func,
    children: PropTypes.node,
  };

  static openPrimarySidebar(event) {
    MobileSidebar.closeSidebar();

    event.stopPropagation();
    document.body.classList.add('primary-sidebar-opened');
  }

  static openSecondarySidebar(event) {
    MobileSidebar.closeSidebar();

    event.stopPropagation();
    document.body.classList.add('secondary-sidebar-opened');
  }

  static closeSidebar() {
    document.body.classList.remove(
      'primary-sidebar-opened',
      'secondary-sidebar-opened',
    );
  }

  closeSidebar() {
    MobileSidebar.closeSidebar();

    this.props.onClose && this.props.onClose();
  }

  static toggleFilterGroupList(event) {
    const groupContainerNode = event.target.parentElement;
    if (groupContainerNode.classList.contains('closed')) {
      groupContainerNode.classList.remove('closed');
    } else {
      groupContainerNode.classList.add('closed');
    }
  }

  render() {
    const { type, title, children } = this.props;

    return (
      <div className={`c-mobile-sidebar c-mobile-sidebar--${type}`}>
        <div
          className="c-mobile-sidebar__overlay"
          onClick={() => this.closeSidebar()}
        />
        <div className="c-mobile-sidebar__content">
          <span className="c-mobile-sidebar__title">{title}</span>
          <span
            className="c-mobile-sidebar__closer"
            onClick={() => this.closeSidebar()}
          />
          {children}
        </div>
      </div>
    );
  }
}
