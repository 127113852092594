import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './NavigationDropdown.scss';

export class NavigationDropdown extends Component {
  static propTypes = {
    value: PropTypes.string,
    options: PropTypes.object,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      value: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      value: state.value || props.value,
    };
  }

  toggleDropdown() {
    this.setState((state) => {
      return { opened: !state.opened };
    });
  }

  getValue(target) {
    if (target.dataset.value) {
      return target.dataset.value;
    } else if (target.parentNode) {
      return this.getValue(target.parentNode);
    }
    return false;
  }

  onChangeValue(event) {
    const value = this.getValue(event.target);

    this.setState({ value });

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value);
    }
  }

  render() {
    const { options, t } = this.props;
    const { value, opened } = this.state;

    if (!options) {
      return null;
    }

    return (
      <div
        onClick={() => this.toggleDropdown()}
        className={`c-navigation-dropdown ${opened ? 'opened' : ''}`}
      >
        <span className="label">
          {t(options[value])}{' '}
          <i className="material-icons c-navigation-dropdown__icon">
            keyboard_arrow_down
          </i>
        </span>
        <ul
          onClick={(event) => this.onChangeValue(event)}
          className="c-navigation-dropdown__list"
        >
          {Object.keys(options).map((key) => (
            <li
              key={key}
              data-value={key}
              className="c-navigation-dropdown__list__item"
            >
              {t(options[key])}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default withTranslation()(NavigationDropdown);
