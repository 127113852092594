import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import AgendaStreamLogo from '../../../shared/AgendaStreamLogo/AgendaStreamLogo';

import './FilterCheckbox.scss';

export default function FilterCheckbox(props) {
  const {
    colorIndex,
    value,
    label,
    count,
    countEnabled = false,
    checked,
    disabled,
    onChange,
    checkboxName,
    logo,
  } = props;
  const isLogo = checkboxName === 'stream' && logo;
  const checkboxClass = classnames('c-filter-checkbox', {
    'c-filter-checkbox--with-logo': isLogo,
  });

  return (
    <label
      className={checkboxClass}
      data-color-index={colorIndex + 0 >= 0 ? colorIndex : 'null'}
      data-test-value={value}
    >
      <input
        type="checkbox"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={(event) => onChange(event)}
      />
      <span>
        {label} {countEnabled && `(${count})`}
      </span>
      {isLogo && <AgendaStreamLogo logo={logo} logoName={label} isFilter />}
    </label>
  );
}

FilterCheckbox.propTypes = {
  colorIndex: PropTypes.any,
  value: PropTypes.string,
  checkboxName: PropTypes.string,
  logo: PropTypes.string,
  count: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  countEnabled: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
